<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="扩展名称"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-input   v-decorator="['ikey',{rules: [{ required: true, message: '请填写扩展字段名称' }]}]" placeholder="请填写扩展字段名称">
        </a-input>
      </a-form-item>
      <a-form-item  label="扩展值" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input   v-decorator="['value',{rules: [{ required: true, message: '请填写扩展字段值' }]}]" placeholder="请填写扩展字段值">
        </a-input>
      </a-form-item>
    </a-form>
    <div>
    </div>
  </a-spin>
</template>

<script>
  import { extendFormVO } from './common/extendFormVO'
  import SERVICE_URLS from '@/api/service.url'
  import entityCRUDCallback from '@/views/common/mixins/entityCURDCallback'
  export default {
    name: 'Form',
    components: {  },
    mixins: [entityCRUDCallback],
    props: {
      systemModule: {
        type: String,
        required: true
      },
      instanceId: {
        type: Number,
        required: true
      },
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.extendInfo,
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        initFormItem: extendFormVO(),
        showFormItem: false,
        spinShow: false
      }
    },
    watch: {
      instanceId(id) {
        this.instanceId = id
      }
    },
    methods: {
      setVOFields (values) {
        this.formItem.instanceId = this.instanceId
        this.formItem.systemModule = this.systemModule
        Object.assign(this.formItem, values)
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          ikey: this.formItem.ikey,
          value: this.formItem.value,
          sorter: this.formItem.sorter,
        })
      },
      /*上传文件回调方法*/
      getFileData (data) {
        this.formItem.fileIds = []
        data.forEach(item => {
          this.formItem.fileIds.push(item.id)
        })
      },
    }
  }
</script>

<style scoped>
</style>